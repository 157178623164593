export const MIRRORS_EU_LEGAL = [
  'bodycoach',
  'bodymanagement',
  'bodytransformation',
  'bodywellness',
  'changeyourself',
  'dietplanners',
  'energybooster',
  'fastingplan',
  'fastingtrainer',
  'healthydietfitness',
  'healthylifetime',
  'healthyplans',
  'loseyourweight',
  'mealsplan',
  'weightcutting',
  'yourbodywell',
];

export const MIRRORS_FOR_AMO_APP = [
  'bodycoach',
  'perfectbody',
  'bodytransformation',
];
