import { memo, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import Link from 'next/link';

import { useRouter } from 'next/router';

import { RoutePath } from 'src/constants/route-path';
import { H1, H2, Caption, A, ButtonTextBig } from 'src/components/Text';
import ErrorNotification from 'src/components/ErrorNotification';

import { getCookie, setCookie } from 'src/utils/cookie';

import { MIRRORS_FOR_AMO_APP } from 'src/constants/mirrors-eu-legal';

import { StepProps } from '../../models';

import {
  StepGenderWrapper,
  StepGenderChoices,
  StepGenderItem,
  GenderIllustration,
  GenderImg,
  GenderBtnContainer,
  GenderBtn,
  PrivacyCheckbox,
  PrivacyWrapper,
  EmailConfirmWrapper,
  EmailConfirmCheckbox,
  CaptionWrapper,
} from './step-gender-styled';

// eslint-disable-next-line react/display-name
export const StepGender = memo(
  ({
    data,
    onSubmit,
    showEmailCheckbox,
    userCountryInfo,
  }: StepProps & {
    userCountryInfo: { countryCode: string };
  }): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation('quiz');

    const [privacyChecked, togglePrivacy] = useState(true);
    const [emailChecked, toggleEmail] = useState(true);

    const [showError, toggleError] = useState(false);

    const errorRef = useRef();

    useEffect(() => {
      setCookie('wantReceiveMarketingEmails', emailChecked ? '1' : '0');
    }, [emailChecked]);

    useEffect(() => {
      if (errorRef.current) {
        // @ts-ignore
        errorRef.current.scrollIntoView();
      }
    }, [showError]);

    useEffect(() => {
      if (Object.values(router.query).length <= 2) {
        toggleEmail(false);
        togglePrivacy(false);
      }
    }, []);

    return (
      <>
        <StepGenderWrapper>
          <H1>{data.title}</H1>
          <H2>{data.subtitle}</H2>
          <StepGenderChoices>
            {data.nav.map((nav): JSX.Element[] =>
              data.selects[nav.id].options.map((option): JSX.Element => {
                const illustration = `/images/gender-${option.analyticId}.png`;
                return (
                  <StepGenderItem
                    key={option.id}
                    onClick={
                      privacyChecked
                        ? (): void =>
                            onSubmit({
                              formValue: { [option.id]: option.value },
                            })
                        : (): void => {
                            toggleError(true);
                          }
                    }
                  >
                    <GenderIllustration>
                      <img
                        src={illustration}
                        alt={option.title}
                        loading="lazy"
                      />
                    </GenderIllustration>
                    <GenderBtn>
                      <GenderBtnContainer>
                        <GenderImg>
                          <img
                            src={option.icon}
                            alt={option.title}
                            loading="lazy"
                          />
                        </GenderImg>
                        <ButtonTextBig>{option.title}</ButtonTextBig>
                      </GenderBtnContainer>
                    </GenderBtn>
                  </StepGenderItem>
                );
              })
            )}
          </StepGenderChoices>
          <PrivacyWrapper>
            <PrivacyCheckbox
              className={privacyChecked ? 'checked' : ''}
              role="button"
              onClick={(): void => {
                togglePrivacy(!privacyChecked);
              }}
            />
            <Caption>
              {t('goal.agreement')}{' '}
              <Link
                href={`${RoutePath.termsOfService}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.terms')}</A>
              </Link>
              ,{' '}
              <Link
                href={`${RoutePath.privacyPolicy}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.privacy')}</A>
              </Link>
              ,{' '}
              <Link
                href={`${RoutePath.subscriptionTerms}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.subscription')}</A>
              </Link>
              {', '}
              <Link
                href={`${RoutePath.cookiePolicy}`}
                passHref
                prefetch={false}
              >
                <A>{t('goal.cookiePolicy')}</A>
              </Link>
            </Caption>
          </PrivacyWrapper>
          {showEmailCheckbox && (
            <EmailConfirmWrapper>
              <EmailConfirmCheckbox
                className={emailChecked ? 'checked' : ''}
                role="button"
                onClick={(): void => {
                  toggleEmail(!emailChecked);
                }}
              />
              <Caption>
                {t('goal.email')}
                {process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'}{' '}
                {t('goal.emailEnd')}
              </Caption>
            </EmailConfirmWrapper>
          )}
          {MIRRORS_FOR_AMO_APP.includes(
            process.env.NEXT_PUBLIC_MIRROR_ID as string
          ) && userCountryInfo?.countryCode === 'USA' ? (
            <CaptionWrapper>
              <Caption>
                AmoApp Inc. 3753 Howard Hughes Parkway, Suite 200, Las Vegas,
                Nevada, 89169, United States of America Employer Identification
                Number: 88-1870386
              </Caption>
            </CaptionWrapper>
          ) : (
            <CaptionWrapper>
              <Caption>
                {process.env.NEXT_PUBLIC_MIRROR_ID === 'perfectbody'
                  ? 'ROCK PARADISE LIMITED, 5-9 Main Street, GX11 1AA, Gibraltar, Gibraltar.'
                  : 'AmoApps Limited, 1st Floor, Georgiou Christoforou 8, 2012, Strovolos, Nicosia, Cyprus. Registered number: HE 450507'}
              </Caption>
            </CaptionWrapper>
          )}

          {showError && !privacyChecked && (
            <ErrorNotification>{t('goal.error')}</ErrorNotification>
          )}
        </StepGenderWrapper>
      </>
    );
  }
);

export default StepGender;
